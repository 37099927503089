import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Heading, { Type } from "../../UI/Heading"
import Button from "../../UI/Button"

import { Section, Content, Description } from "./styles"

const NotFound = ({ title, description, buttonText }) => {
  const onClick = () => navigate("/")
  return (
    <Section>
      <Content>
        <Heading type={Type.h1}>{title}</Heading>
        <Description>{description}</Description>
        <Button onClick={onClick}>{buttonText}</Button>
      </Content>
    </Section>
  )
}

NotFound.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
}

export default NotFound
