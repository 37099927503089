import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFound from "../components/sections/NotFound"

const NotFoundPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO
        title={`404: ${intl.formatMessage({ id: "notfound.title" })}`}
        description={intl.formatMessage({ id: "notfound.description" })}
      />
      <NotFound
        title={intl.formatMessage({ id: "notfound.title" })}
        description={intl.formatMessage({ id: "notfound.description" })}
        buttonText={intl.formatMessage({ id: "notfound.btn" })}
      />
    </Layout>
  )
}

export default NotFoundPage
